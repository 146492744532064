<template>
  <div
    v-tooltip.auto="toolTipItem"
    class="c-icon-wrap"
    :class="{ 'c-icon-wrap-disabled': isDisabled }"
  >
    <button
      v-if="isButton"
      type="button"
      :disabled="isDisabled"
      @click="$emit('icon-click')"
    >
      <svg
        :is="componentName"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        :aria-labelledby="iconName"
        role="presentation"
        :width="
          iconName === 'backward' ||
            iconName === 'forward' ||
            iconName === 'newTab'
            ? styleVariablesArrow
            : styleVariables
        "
        :height="styleVariables"
        :class="'c-icon-color-' + color"
      />
    </button>
    <svg
      :is="componentName"
      v-else
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :aria-labelledby="iconName"
      role="presentation"
      :width="
        iconName === 'backward' ||
          iconName === 'forward' ||
          iconName === 'newTab'
          ? styleVariablesArrow
          : styleVariables
      "
      :height="styleVariables"
      :class="'c-icon-color-' + color"
    />
  </div>
</template>

<script>
// dummy
import dummyIcon from '../../assets/icons/common/dummy.svg' // ダミーアイコン
import dummyImgIcon from '../../assets/icons/dummy-img.svg' // ダミー画像アイコン

// 共通使用アイコン
import arrowIcon from '../../assets/icons/common/arrow.svg' // 矢印
import arrowBackIcon from '../../assets/icons/common/arrow_back.svg' // 矢印
import backwardIcon from '../../assets/icons/common/backward.svg' // 前のページに戻る
import forwardIcon from '../../assets/icons/common/forward.svg' // 次のページに進む
import toggleIcon from '../../assets/icons/common/toggle.svg' // セレクトボックスなどのトグル
import undoIcon from '../../assets/icons/common/undo.svg' // もとに戻す
import redoIcon from '../../assets/icons/common/redo.svg' // やり直し
import changeIcon from '../../assets/icons/common/change.svg' // 前処理の変換表など変化を表示する際に使用
import closeIcon from '../../assets/icons/common/close.svg' // 閉じる
import checkIcon from '../../assets/icons/common/check.svg' // チェックマーク・OK
import errorIcon from '../../assets/icons/common/error.svg' // エラー
import checkboxOffIcon from '../../assets/icons/common/checkbox_off.svg' // チェックボックスOFF
import checkboxOnIcon from '../../assets/icons/common/checkbox_on.svg' // チェックボックスON
import clipboardIcon from '../../assets/icons/common/clipboard.svg' // リンクなどをクリップボードにコピー
import copyIcon from '../../assets/icons/common/copy.svg' // 複製
import deleteIcon from '../../assets/icons/common/delete.svg' // 削除
import detailIcon from '../../assets/icons/common/detail.svg' // 詳細・概要
import historyIcon from '../../assets/icons/common/history.svg' // 履歴
import downloadIcon from '../../assets/icons/common/download.svg' // ダウンロード
import uploadIcon from '../../assets/icons/common/upload.svg' // アップロード
import editIcon from '../../assets/icons/common/edit.svg' // 編集開始
import eyeCloseIcon from '../../assets/icons/common/eye_close.svg' // パスワードなど非表示要素を表示
import eyeOpenIcon from '../../assets/icons/common/eye_open.svg' // パスワードなど非表示要素を非表示
import gridIcon from '../../assets/icons/common/grid.svg' // グリッド
import listIcon from '../../assets/icons/common/list.svg' // リスト
import plusIcon from '../../assets/icons/common/plus.svg' // 閉じている要素を表示
import minusIcon from '../../assets/icons/common/minus.svg' // プラスで表示した要素を閉じる
import newTabIcon from '../../assets/icons/common/new_tab.svg' // 新しいタブを開く
import popupIcon from '../../assets/icons/common/popup.svg' // ポップアップを表示
import resetIcon from '../../assets/icons/common/reset.svg' // リセット
import saveIcon from '../../assets/icons/common/save.svg' // 保存
import searchIcon from '../../assets/icons/common/search.svg' // 検索
import languageIcon from '../../assets/icons/common/language.svg' // 言語切替
import menuIcon from '../../assets/icons/common/menu.svg' // メニュー
import ownerIcon from '../../assets/icons/common/owner.svg' // 所有者
import homeIcon from '../../assets/icons/common/home.svg' // TOPに戻る
import infoIcon from '../../assets/icons/common/info.svg' // Tipsなどの説明を表示
import helpIcon from '../../assets/icons/common/help.svg' // ヘルプ
import logoutIcon from '../../assets/icons/common/logout.svg' // ヘルプ

// ページタイトルやサイドバーに表示するアイコン
import optionIcon from '../../assets/icons/page_title/option.svg' // アカウント情報
import teamIcon from '../../assets/icons/page_title/team.svg' // 組織
import upgradeIcon from '../../assets/icons/page_title/upgrade.svg' // プランを変更
import projectIcon from '../../assets/icons/page_title/project.svg' // プロジェクト
import dashbordIcon from '../../assets/icons/page_title/dashbord.svg' // ダッシュボード
import trainedAiIcon from '../../assets/icons/page_title/trained_ai.svg' // 学習済みAI管理
import inferenceIcon from '../../assets/icons/page_title/inference.svg' // 推論
import serviceIcon from '../../assets/icons/page_title/service.svg' // 現在作成中 サービス
import datasetIcon from '../../assets/icons/page_title/dataset.svg' // 現在作成中 データセット
import preprocIcon from '../../assets/icons/page_title/preproc.svg' // 現在作成中 前処理
import recipeIcon from '../../assets/icons/page_title/recipe.svg' // レシピ
import targetIcon from '../../assets/icons/page_title/target.svg' // 予測対象を選択
import trainIcon from '../../assets/icons/page_title/train.svg' // 学習

// データセット設定・管理画面で使用するアイコン
import datasourceIcon from '../../assets/icons/dataset/datasource.svg' // データソース
import datasourceResetIcon from '../../assets/icons/dataset/datasource_reset.svg' // データソースを再設定
import statisticsIcon from '../../assets/icons/dataset/statistics.svg' // 統計情報を表示

// 推論画面で使用するアイコン
import num1Icon from '../../assets/icons/inference/num_1.svg' // 推論の手順１
import num2Icon from '../../assets/icons/inference/num_2.svg' // 推論の手順２
import num3Icon from '../../assets/icons/inference/num_3.svg' // 推論の手順３
import num4Icon from '../../assets/icons/inference/num_4.svg' // 推論の手順４
import inferenceCompIcon from '../../assets/icons/inference/comp.svg' // 推論完了

// 前処理画面で使用するアイコン
import boxPlotIcon from '@/assets/icons/preproc/boxPlot.svg' // 箱ひげ図
import preprocDetailIcon from '../../assets/icons/preproc/detail.svg' // 前処理で列の詳細を見るボタン
import histogramIcon from '../../assets/icons/preproc/histogram.svg' // ヒストグラムを表示
import linegraphIcon from '../../assets/icons/preproc/linegraph.svg' // 線グラフを表示
import DropOutlierPreprocessingIcon from '@/assets/icons/preproc/outlier.svg' // 外れ値処理
import DropColumnPreprocessingIcon from '../../assets/icons/preproc/drop_column.svg' // 列を削除
import DropNullPreprocessingIcon from '../../assets/icons/preproc/drop_null.svg' // 欠損値を削除
import FillNullPreprocessingIcon from '../../assets/icons/preproc/fill_null.svg' // 欠損値を埋める
import ReplacePreprocessingIcon from '../../assets/icons/preproc/replace.svg' // ラベルエンコーディング
import ReplaceDummyPreprocessingIcon from '../../assets/icons/preproc/replace_dummy.svg' // ダミーコーディング
import ReplaceOneHotPreprocessingIcon from '../../assets/icons/preproc/replace_one_hot.svg' // One-hotエンコーディング
import InputPreprocessingIcon from '../../assets/icons/preproc/input.svg' // 入力

// プロジェクト管理・プロジェクトテンプレート選択画面
import projectAddIcon from '../../assets/icons/project/add.svg' // プロジェクトの新規作成
import projectMoveIcon from '../../assets/icons/project/item_copy.svg' // 別のプロジェクトに移動
import selectGoalIcon from '../../assets/icons/project/select_goal.svg' // テンプレートの実現したいこと
import selectIndustryIcon from '../../assets/icons/project/select_industry.svg' // テンプレートの業界業種
import selectTargetIcon from '../../assets/icons/project/select_target.svg' // テンプレートの予測分類対象

// レシピ管理・作成画面
import alignmentIcon from '../../assets/icons/recipe/alignment.svg' // レシピブロックを整列
import changeHorizontalIcon from '../../assets/icons/recipe/change_horizontal.svg' // レシピブロックを水平方向に整列
import changeVerticalIcon from '../../assets/icons/recipe/change_vertical.svg' // レシピブロックを垂直方向に整列
import resetZoomIcon from '../../assets/icons/recipe/reset_zoom.svg' // レシピ画面のズームをリセット
import resetMoveIcon from '../../assets/icons/recipe/reset_move.svg' // レシピ画面の上下左右移動をリセット
import sidebarCloseLeftIcon from '../../assets/icons/recipe/sidebar_close_left.svg' // サイドバー（左）を閉じる
import sidebarCloseRightIcon from '../../assets/icons/recipe/sidebar_close_right.svg' // サイドバー（右）を閉じる
import autoRecipeTypeIcon from '../../assets/icons/recipe/auto.svg' // AutoFlow
import classificationRecipeTypeIcon from '../../assets/icons/recipe/classification.svg' // 分類
import regressionRecipeTypeIcon from '../../assets/icons/recipe/regression.svg' // 回帰
import nlpRecipeTypeIcon from '../../assets/icons/recipe/nlp.svg' // 自然言語
import deepRecipeTypeIcon from '../../assets/icons/recipe/deep.svg' // ディープラーニング
import finetuningDeepRecipeTypeIcon from '../../assets/icons/recipe/finetuning.svg' // ディープラーニング
import otherRecipeTypeIcon from '../../assets/icons/recipe/other.svg' // その他
import hrRecipeTypeIcon from '../../assets/icons/recipe/hr.svg' // 人事
import timeRecipeTypeIcon from '../../assets/icons/recipe/time.svg' // 時系列
// eslint-disable-next-line import/no-duplicates
import accRecipeTypeIcon from '../../assets/icons/recipe/acc.svg' // 制度評価
// eslint-disable-next-line import/no-duplicates
import lossRecipeTypeIcon from '../../assets/icons/recipe/acc.svg' // 損失関数（制度評価と同じ）
import inputRecipeTypeIcon from '../../assets/icons/recipe/input.svg' // データ入り口ラベル入り口
import processingSpecifyColRecipeTypeIcon from '../../assets/icons/recipe/processing.svg' // 特定の列への処理
import processingConcatColRecipeTypeIcon from '../../assets/icons/recipe/processing-merge.svg' // 列結合
import optimizationRecipeTypeIcon from '../../assets/icons/recipe/optimization.svg' // 最適化
import marketplaceRecipeTypeIcon from '../../assets/icons/recipe/marketplace.svg' // マーケットプレイス
import processingUnderSamplingRecipeTypeIcon from '../../assets/icons/recipe/under-sampling.svg' // アンダーサンプリング用

// サービス管理
import serviceStatusIcon from '../../assets/icons/service/status.svg' // サービスの使用状況

// 予測対象を選択画面
import selectListIcon from '../../assets/icons/target_select/list.svg' // 予測対象を選択画面で列のみリスト表示

// 学習済みAI管理画面
import accuracyIcon from '../../assets/icons/trained_ai/accuracy.svg' // 精度
import expectedEffectIcon from '../../assets/icons/trained_ai/expected_effect.svg' // 期待する効果
import featureImportanceIcon from '../../assets/icons/trained_ai/feature_importance.svg' // 現在作成中 重要度
import bubbleChartIcon from '../../assets/icons/trained_ai/bubble_chart.svg' // 重要度の分散
import compareChartIcon from '../../assets/icons/trained_ai/compare_chart.svg' // 重要度の大きさ比較
import trainedInfoIcon from '../../assets/icons/trained_ai/info.svg' // 学習情報
import textminingIcon from '../../assets/icons/trained_ai/textmining.svg' // テキストマイニング
import testDatasetIcon from '../../assets/icons/trained_ai/test_dataset.svg' // テスト予測
import timeseriesResultIcon from '../../assets/icons/trained_ai/result.svg' // 時系列分析の結果
import timeseriesTrendIcon from '../../assets/icons/trained_ai/trend.svg' // トレンド
import timeseriesChangePointIcon from '../../assets/icons/trained_ai/change_point.svg' // 変化点
import timeseriesGraphIcon from '../../assets/icons/trained_ai/graph.svg' // 相関グラフ
import timeseriesOthersIcon from '../../assets/icons/trained_ai/others.svg' // そのほかのグラフ
import adjustIcon from '../../assets/icons/trained_ai/adjust.svg' // 調節アイコン
import closerIcon from '../../assets/icons/trained_ai/closer.svg' // 値に近づける
import maximizeIcon from '../../assets/icons/trained_ai/maximize.svg' // 最大化
import minimizeIcon from '../../assets/icons/trained_ai/minimize.svg' // 最小化
import optimizationIcon from '../../assets/icons/trained_ai/optimization.svg' // 最適化

// 学習画面
import trainRetrainIcon from '../../assets/icons/train/retrain.svg' // 再学習
import trainTopIcon from '../../assets/icons/train/top.svg' // 最も精度が高いAI

import customblockIcon from '../../assets/icons/customblock.svg' // カスタムブロック
import customblockRecipeTypeIcon from '../../assets/icons/customblock-recipe.svg'

// テキストマイニング
import textListIcon from '../../assets/icons/text_mining/text-list.svg' // テキストの一覧
import textTopicDetailIcon from '../../assets/icons/text_mining/text-topic.svg' // テキストのトピックの詳細
import textTopicIcon from '../../assets/icons/text_mining/topic-detail.svg' // トピックの詳細
import ldaIcon from '../../assets/icons/text_mining/lda.svg' // トピックの詳細
import coOccurrenceIcon from '../../assets/icons/text_mining/co-occurrence.svg' // 共起ネットワーク
import frequenciesWordcloudIcon from '../../assets/icons/text_mining/wordcloud-frequencies.svg' // 頻度とワードクラウド
import frequenciesIcon from '../../assets/icons/text_mining/frequencies.svg' // 頻度
import wordcloudIcon from '../../assets/icons/text_mining/wordcloud.svg' // ワードクラウド
import sentimentsIcon from '../../assets/icons/text_mining/sentiments.svg' // 感情分析
import sentimentNegativeIcon from '../../assets/icons/text_mining/sentiment-negative.svg' // 感情分析 ネガティブ
import sentimentPositiveIcon from '../../assets/icons/text_mining/sentiment-positive.svg' // 感情分析 ポジティブ

// クラスタリング
import clusteringIcon from '../../assets/icons/clustering/clustering.svg' // クラスタリング
import clusteringResultIcon from '../../assets/icons/clustering/clustering-result.svg' // クラスタリング
import clusteringDistributionIcon from '../../assets/icons/clustering/clustering-distribution.svg' // クラスタリング
import clusteringRecipeTypeIcon from '../../assets/icons/recipe/clustering.svg' // カスタムブロック
const ragIcon = customblockIcon
const ragRecipeTypeIcon = customblockRecipeTypeIcon // クラスタリング

// 暫定でヒストグラムのアイコン
const StandardizePreprocessingIcon = histogramIcon // 標準化
const YeoJohnsonPreprocessingIcon = histogramIcon // 対数変換
const BinningPreprocessingIcon = histogramIcon // 離散化
const ReplaceNumericConverterPreprocessingIcon = ReplacePreprocessingIcon // AIラベルエンコーディング
const ReplaceDataNormalizerPreprocessingIcon = ReplacePreprocessingIcon // AIラベルエンコーディング

const DeleteRowsPreprocessingIcon = deleteIcon

export default {
  components: {
    dummyIcon,
    dummyImgIcon,

    // common
    arrowIcon,
    arrowBackIcon,
    forwardIcon,
    backwardIcon,
    undoIcon,
    changeIcon,
    checkIcon,
    checkboxOffIcon,
    checkboxOnIcon,
    clipboardIcon,
    closeIcon,
    copyIcon,
    dashbordIcon,
    datasetIcon,
    deleteIcon,
    detailIcon,
    downloadIcon,
    editIcon,
    errorIcon,
    eyeCloseIcon,
    eyeOpenIcon,
    redoIcon,
    gridIcon,
    helpIcon,
    historyIcon,
    homeIcon,
    inferenceIcon,
    infoIcon,
    languageIcon,
    listIcon,
    menuIcon,
    minusIcon,
    newTabIcon,
    optionIcon,
    ownerIcon,
    plusIcon,
    popupIcon,
    preprocIcon,
    projectIcon,
    recipeIcon,
    resetIcon,
    saveIcon,
    searchIcon,
    serviceIcon,
    targetIcon,
    teamIcon,
    toggleIcon,
    trainIcon,
    trainedAiIcon,
    upgradeIcon,
    logoutIcon,
    ragIcon,

    // dataset
    datasourceIcon,
    datasourceResetIcon,
    statisticsIcon,
    uploadIcon,

    // inference
    num1Icon,
    num2Icon,
    num3Icon,
    num4Icon,
    inferenceCompIcon,

    // preproc
    boxPlotIcon,
    preprocDetailIcon,
    histogramIcon,
    linegraphIcon,
    DropColumnPreprocessingIcon,
    DropNullPreprocessingIcon,
    DropOutlierPreprocessingIcon,
    FillNullPreprocessingIcon,
    ReplacePreprocessingIcon,
    ReplaceDummyPreprocessingIcon,
    ReplaceOneHotPreprocessingIcon,
    InputPreprocessingIcon,
    StandardizePreprocessingIcon,
    YeoJohnsonPreprocessingIcon,
    BinningPreprocessingIcon,
    ReplaceNumericConverterPreprocessingIcon,
    ReplaceDataNormalizerPreprocessingIcon,
    DeleteRowsPreprocessingIcon,

    // project
    projectAddIcon,
    selectGoalIcon,
    selectIndustryIcon,
    selectTargetIcon,
    projectMoveIcon,

    // recipe
    alignmentIcon,
    changeHorizontalIcon,
    changeVerticalIcon,
    resetZoomIcon,
    resetMoveIcon,
    sidebarCloseLeftIcon,
    sidebarCloseRightIcon,
    autoRecipeTypeIcon,
    classificationRecipeTypeIcon,
    regressionRecipeTypeIcon,
    nlpRecipeTypeIcon,
    deepRecipeTypeIcon,
    finetuningDeepRecipeTypeIcon,
    otherRecipeTypeIcon,
    hrRecipeTypeIcon,
    timeRecipeTypeIcon,
    accRecipeTypeIcon,
    lossRecipeTypeIcon,
    inputRecipeTypeIcon,
    processingSpecifyColRecipeTypeIcon,
    processingConcatColRecipeTypeIcon,
    optimizationRecipeTypeIcon,
    processingUnderSamplingRecipeTypeIcon,
    marketplaceRecipeTypeIcon,
    clusteringRecipeTypeIcon,
    ragRecipeTypeIcon,

    // service
    serviceStatusIcon,

    // targetSelect
    selectListIcon,

    // trainedAi
    accuracyIcon,
    expectedEffectIcon,
    featureImportanceIcon,
    bubbleChartIcon,
    compareChartIcon,
    trainedInfoIcon,
    textminingIcon,
    testDatasetIcon,
    timeseriesResultIcon,
    timeseriesTrendIcon,
    timeseriesChangePointIcon,
    timeseriesGraphIcon,
    timeseriesOthersIcon,
    adjustIcon,
    closerIcon,
    maximizeIcon,
    minimizeIcon,
    optimizationIcon,

    // train
    trainRetrainIcon,
    trainTopIcon,

    // customblock
    customblockIcon,
    customblockRecipeTypeIcon,

    // テキストマイニング
    textListIcon,
    textTopicDetailIcon,
    textTopicIcon,
    ldaIcon,
    coOccurrenceIcon,
    frequenciesWordcloudIcon,
    frequenciesIcon,
    wordcloudIcon,
    sentimentsIcon,
    sentimentNegativeIcon,
    sentimentPositiveIcon,

    // クラスタリング
    clusteringIcon,
    clusteringResultIcon,
    clusteringDistributionIcon
  },
  props: {
    /** アイコンの名前を入れる（Storybook参照） */
    iconName: {
      type: String,
      default: 'dummy'
    },
    /** アイコンの大きさを指定 [ default, large, small, title, min, huge ] 数値での指定も可能です */
    size: {
      type: [Number, String],
      default: 'default'
    },
    /** アイコンの色 [ default, caution, emphasis, gray, link, link-default, off, function(白), green, accept, negative, positive ] */
    color: {
      type: String,
      default: 'default',
      require: false
    },
    /** アイコンをボタンとして使用する */
    isButton: {
      type: Boolean,
      default: false,
      require: false
    },
    /** tooltipを表示するi18n内にtooltipの情報がない場合は表示されない。common.icon.tooltipContents参照 */
    showTips: {
      type: Boolean,
      default: false,
      require: false
    },
    /** tooltipの表示非表示のフラグ。showTipsを入れた上でtooltipを非表示にする場合があるときに使う */
    showTipsFlag: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 押下不可状態 */
    isDisabled: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  computed: {
    componentName() {
      const name = this.iconName + 'Icon'
      if (name in this.$options.components) {
        return name
      }
      return 'dummyIcon'
    },
    styleVariables() {
      let iconSize = this.size
      if (isNaN(iconSize) === false) {
        iconSize = (iconSize / 1920) * 100
      } else {
        switch (iconSize) {
          case 'default':
            iconSize = (28 / 1920) * 100
            break
          case 'large':
            iconSize = (32 / 1920) * 100
            break
          case 'small':
            iconSize = (20 / 1920) * 100
            break
          case 'title':
            iconSize = (48 / 1920) * 100
            break
          case 'min':
            iconSize = (16 / 1920) * 100
            break
          case 'huge':
            iconSize = (64 / 1920) * 100
            break
          default:
            iconSize = (28 / 1920) * 100
        }
      }
      return iconSize + 'vw'
    },
    styleVariablesArrow() {
      let iconSize = this.size
      if (isNaN(iconSize) === false) {
        iconSize = (iconSize / 1920) * 100
      } else {
        const defaultSize = 18
        switch (iconSize) {
          case 'default':
            iconSize = (defaultSize / 1920) * 100
            break
          case 'large':
            iconSize = ((defaultSize * 1.25) / 1920) * 100
            break
          case 'small':
            iconSize = ((defaultSize * 0.75) / 1920) * 100
            break
          case 'title':
            iconSize = ((defaultSize * 1.75) / 1920) * 100
            break
          case 'min':
            iconSize = ((defaultSize * 0.5) / 1920) * 100
            break
          case 'huge':
            iconSize = ((defaultSize * 2.5) / 1920) * 100
            break
          default:
            iconSize = (defaultSize / 1920) * 100
        }
      }
      return iconSize + 'vw'
    },
    toolTipItem() {
      if (!this.showTips) return null
      if (!this.$te('common.icon.tooltipContents.' + this.iconName)) return null
      return {
        content: this.$t('common.icon.tooltipContents.' + this.iconName),
        trigger: this.showTipsFlag ? 'manual' : 'hover',
        show: this.showTipsFlag,
        delay: { show: 200, hide: 200 }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-icon {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      transition: fill $transition-base;
    }
    &-disabled {
      > button {
        cursor: not-allowed;
      }
    }
  }
  &-color {
    &-default {
      fill: $text-main;
    }
    &-caution {
      fill: $text-caution;
    }
    &-emphasis {
      fill: $text-decoration;
    }
    &-gray {
      fill: $text-sub;
    }
    &-link {
      fill: $text-link;
    }
    &-link-default {
      fill: $text-main;
    }
    &-off {
      fill: $border-gray;
    }
    &-function {
      fill: $text-func;
    }
    &-green {
      fill: $green;
    }
    &-accept {
      fill: $blue;
    }
    &-negative {
      fill: $color-negative;
    }
    &-positive {
      fill: $color-positive;
    }
  }
}
</style>
